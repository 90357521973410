
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'

import { State, Action } from 'vuex-class'

@Component
export default class DisclaimerBody extends Vue {
  @State(({ userState }) => userState.userLinks) userLinks!: any

  @Action getAssistant!: Function

  public isValid = undefined
  public camera = 'auto'
  public result = null

  get validationPending() {
    return this.isValid === undefined && this.camera === 'off'
  }

  protected onInit(promise: any) {
    promise.catch(console.error).then(this.resetValidationState)

    //  this.onDecode('104cbba2-42a6-4d63-9056-ae5698ea3838');
  }

  protected resetValidationState() {
    this.isValid = undefined
  }

  protected async onDecode(content: any) {
    this.result = content
    this.turnCameraOff()
    this.getAssistant(content)

    // pretend it's taking really long
    //await this.timeout(100);
    this.turnCameraOn()
  }

  protected turnCameraOn() {
    this.camera = 'auto'
  }

  protected turnCameraOff() {
    this.camera = 'off'
  }

  protected timeout(ms: any) {
    return new Promise(resolve => {
      window.setTimeout(resolve, ms)
    })
  }
}
