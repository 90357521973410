
import { Component, Vue } from 'vue-property-decorator'
import ScanQRHeader from '@/components/ScanQR/ScanQRHeader.vue'
import ScanQRBody from '@/components/ScanQR/ScanQRBody.vue'

@Component({
  components: {
    ScanQRHeader,
    ScanQRBody,
  },
})
export default class Contacts extends Vue {
  // @State(({ userState }) => userState.userPoints) points!: number;
}
